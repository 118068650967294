import { ReactNode, useRef } from 'react';

import { useFormContext } from 'components/Form/FormContext';
import { identity } from 'utils/functional';

import { FieldInternal, FieldInternalProps } from './FieldInternal';

type Props<TValues extends Record<string, unknown>> = {
  className?: string;
  name: string;
  nameOverride?: string;
  label?: ReactNode;
  errorRef?: React.RefObject<HTMLDivElement>;
  inlineLabel?: boolean;
  hidden?: boolean;
  description?: ReactNode;
  renderInput: FieldInternalProps<TValues>['renderInput'];
  mapError?: FieldInternalProps<TValues>['mapError'];
  matchGeneralError?: string;
  inputProps?: FieldInternalProps<TValues>['inputProps'];
  customCompare?: FieldInternalProps<TValues>['customCompare'];
  customErrorMatch?: FieldInternalProps<TValues>['customErrorMatch'];
  noMargin?: boolean;
  noWrap?: boolean;
  success?: boolean;
  customError?: string | null | undefined;
};

export function Field<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TValues extends Record<string, any> = Record<string, any>,
>({
  className,
  name,
  nameOverride,
  label,
  errorRef,
  inlineLabel,
  description,
  renderInput,
  mapError = identity,
  matchGeneralError,
  inputProps = {},
  customCompare,
  customErrorMatch,
  hidden,
  noMargin,
  noWrap,
  success,
  customError,
}: Props<TValues>) {
  const labelRef = useRef<HTMLLabelElement>(null);

  const {
    values,
    errors,
    getValue,
    updateValue,
    updateValueWith,
    fieldQaIdPrefix,
  } = useFormContext<TValues>();

  return (
    <FieldInternal
      className={className}
      values={values}
      errors={errors}
      getValue={getValue}
      // @ts-expect-error TS(2322): Type '<TKey extends keyof TValues>(name: TKey, val... Remove this comment to see the full error message
      updateValue={updateValue}
      updateValueWith={updateValueWith}
      fieldQaIdPrefix={fieldQaIdPrefix || ''}
      inlineLabel={inlineLabel}
      mapError={mapError}
      labelRef={labelRef}
      errorRef={errorRef}
      // @ts-expect-error TS(2322): Type '(params: { name: string; fieldQaIdPrefix: st... Remove this comment to see the full error message
      renderInput={renderInput}
      name={name}
      nameOverride={nameOverride}
      label={label}
      description={description}
      matchGeneralError={matchGeneralError}
      inputProps={inputProps}
      // @ts-expect-error TS(2322): Type '((previousProps: FieldInternalProps<TValues>... Remove this comment to see the full error message
      customCompare={customCompare}
      customErrorMatch={customErrorMatch}
      hidden={hidden}
      noMargin={noMargin}
      noWrap={noWrap}
      customError={customError}
      success={success}
    />
  );
}
