import cloneDeep from 'lodash/cloneDeep';
import {
  Component,
  ComponentType,
  ElementRef,
  ElementType,
  FormEventHandler,
  ReactNode,
} from 'react';
import { flushSync } from 'react-dom';
import deepEqual from 'react-fast-compare';

import { trackEvent } from 'utils/analytics/track/trackEvent';
import { arrToObject, getIn, partial, updateIn } from 'utils/functional';
import { zip } from 'utils/functional/array/zip';
import { scrollIntoView } from 'utils/scrollIntoView';

import {
  FormContext,
  FormContextValue,
  FormMapErrorFunction,
  FormRenderProps,
  FormSubmitFunction,
  SubmitInterceptor,
  SubmittedInterceptor,
} from './FormContext';
import { formSubmittingClassName } from './formSubmittingConstants';
import { getFormErrorArray } from './getFormErrorArray';

export function scrollToError(isModal?: boolean) {
  // scroll to the first error that's not a general error if it exists
  // otherwise fall back to general error
  const firstError =
    document.querySelector<HTMLElement>('.error:not(.general-error)') ||
    document.querySelector<HTMLElement>('.general-error');

  if (firstError) {
    scrollIntoView(firstError, {
      padding: 64,
      container: isModal ? 'idealist-modal-container' : undefined,
    });
  }
}

/**
 * @deprecated this method relies on `getIn`, which is also deprecated
 *
 * support nested form attributes by parsing dot notation in field names
 * e.g. "profile.summary"
 */
function getValue(values: Record<string, unknown>, name: string) {
  return getIn(values, name.split('.'));
}

function updateField(
  prev: Record<string, unknown>,
  name: string,
  updater: (value: unknown) => unknown,
) {
  return updateIn(prev, name.split('.'), updater);
}

export type FormUsageContext = 'modal' | 'page';

type Props<TValues extends Record<string, unknown>> = {
  initialValues?: Partial<TValues>;
  analyticsTitle: string;
  usageContext: FormUsageContext;
  id?: string;
  errors?: APIErrors;
  noScrollToError?: boolean;
  hasStickyHeader?: boolean;
  mapError?: FormMapErrorFunction<TValues>;
  onSubmit: FormSubmitFunction<TValues>;
  render?: (renderProps: FormRenderProps<TValues>) => ReactNode;
  children?: ReactNode;
  'data-qa-id'?: string;
  'data-qa'?: Record<string, string>;
  className?: string;
  component?:
    | string
    | ComponentType<{
        'data-qa-id'?: string;
        onSubmit: FormEventHandler;
        className?: string;
        id?: string;
      }>;
  formRef?: {
    current: null | ElementRef<ElementType>;
  };
};

type State<TValues extends Record<string, unknown>> = {
  values: Partial<TValues>;
  errors: APIErrors;
  submitting: boolean;
  submitDisabled: boolean;
  fetchingAddress: boolean;
  prevPropsInitialValues: Partial<TValues>;
  edited: boolean;
};

export class Form<
  TValues extends Record<string, unknown>,
  TContext extends FormContextValue<TValues>,
> extends Component<Props<TValues>, State<TValues>> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    mapError: ({ description }: { description: string }) => description,
    initialValues: {},
  };

  private submitInterceptors: SubmitInterceptor[] = [];

  private submittedInterceptors: SubmittedInterceptor[] = [];

  constructor(props: Props<TValues>) {
    super(props);

    this.state = {
      // @ts-expect-error fix types
      values: cloneDeep(props.initialValues),
      errors: props.errors || {},
      submitting: false,
      submitDisabled: false,
      fetchingAddress: false,
      prevPropsInitialValues: props.initialValues || {},
      edited: false,
    };
  }

  onFetchingAddressComplete: (() => void) | null | undefined;

  // TODO(Karan): do we need this? remove it otherwise
  // See https://www.pivotaltracker.com/story/show/169410492
  // for bug caused by this. This code assumes that all fields in a form
  // will be keys in `this.props.initialValues`
  static getDerivedStateFromProps<TValues extends Record<string, unknown>>(
    props: Props<TValues>,
    state: State<TValues>,
  ) {
    const initialValuesChanged = !deepEqual(
      props.initialValues,
      state.prevPropsInitialValues,
    );
    const currentFields = Object.keys(state.values);
    const newFields = Object.keys(props.initialValues || {}).filter(
      (f) => !currentFields.includes(f),
    );
    const hasNewFields = newFields.length > 0;
    const newValues = newFields.reduce(
      (memo, formField) => ({
        ...memo,
        [formField]: props.initialValues?.[formField],
      }),
      {},
    );

    if (!initialValuesChanged) {
      if (!hasNewFields || state.submitting) {
        return null;
      }

      return {
        values: { ...state.values, ...newValues },
      };
    }

    // if initialValues is updated and the user hasn't changed the updated
    // form fields, update the field's value
    const updatedFormData = Object.keys(props.initialValues || {}).reduce(
      (memo, formField) => {
        const prevDefaultValue = state.prevPropsInitialValues[formField];
        const newDefaultValue = props.initialValues?.[formField];
        const currentValue = state.values[formField];

        const newValue =
          !deepEqual(prevDefaultValue, newDefaultValue) &&
          deepEqual(currentValue, prevDefaultValue)
            ? cloneDeep(newDefaultValue)
            : currentValue;

        return { ...memo, [formField]: newValue };
      },
      newValues,
    );

    return {
      values: updatedFormData,
      prevPropsInitialValues: props.initialValues,
    };
  }

  componentDidUpdate() {
    const { fetchingAddress } = this.state;

    if (!fetchingAddress && this.onFetchingAddressComplete) {
      this.onFetchingAddressComplete();
      this.onFetchingAddressComplete = null;
    }
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  updateValue: TContext['updateValue'] = (name, newValue, callback) =>
    this.updateValueWith(name, () => newValue, callback);

  // updateValueWith
  // single field with updater function (e.g., incrementing):
  // updateValueWith('field1', oldField1 => oldField1 + 1)
  // multiple fields at once (e.g., incrementing):;
  // updateValueWith(['field1', 'field2'], ({field1, field2}) => {field1: field1 + 1, field2: field2 + 1})
  updateValueWith: TContext['updateValueWith'] = (name, update, callback) => {
    this.setState((prevState) => {
      let updatedValues;

      if (typeof name === 'string') {
        updatedValues = updateField(prevState.values, name, update);
      } else {
        const updaterArg = arrToObject(
          // @ts-expect-error TS(2345): Argument of type '[TKey, unknown][]' is not assign... Remove this comment to see the full error message
          zip(name, name.map(partial(getValue, prevState.values))),
        );
        const newValues = update(updaterArg);
        updatedValues = Object.entries(newValues).reduce(
          (acc, [field, val]) => updateField(acc, field, () => val),
          prevState.values,
        );
      }

      return {
        values: updatedValues,
        edited: prevState.edited || !deepEqual(prevState.values, updatedValues),
      };
    }, callback);
  };

  updateValues: TContext['updateValues'] = (update) => {
    this.updateValueWith(Object.keys(update), () => update);
  };

  clearValues: TContext['clearValues'] = () => {
    const { values } = this.state;
    const emptyValues = Object.fromEntries(
      Object.keys(values).map((k) => [k, undefined]),
    ) as Partial<TValues>;

    this.setState({ values: emptyValues });
  };

  updateSubmitting: TContext['updateSubmitting'] = (submitting) => {
    this.setState({ submitting });
  };

  updateSubmitDisabled: TContext['updateSubmitDisabled'] = (submitDisabled) => {
    this.setState({ submitDisabled });
  };

  clearErrors: TContext['clearErrors'] = (names) => {
    this.setState((state) => {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (!(names && names.length)) {
        return {
          errors: {},
        };
      }

      const newErrors = state.errors;
      names.forEach((name) => {
        delete newErrors[name];
      });
      return {
        errors: newErrors,
      };
    });
  };

  handleError: TContext['handleError'] = (error, updatedFormData) => {
    const { analyticsTitle, mapError, noScrollToError, usageContext } =
      this.props;
    const { values } = this.state;

    const errorArray = getFormErrorArray<keyof TValues>(error);

    const errors = errorArray.reduce((acc, errorData) => {
      const { name } = errorData;
      const value = name ? values[name] : undefined;

      if (mapError) {
        const errorMessage = mapError({ ...errorData, value });
        return { ...acc, [name]: errorMessage };
      }
      return acc; // should never happen, but keeps typescript happy
    }, {});

    trackEvent('Generic Form Error', {
      errors,
      form: analyticsTitle,
      form_context: usageContext,
      page_title: document.title,
    });

    this.setState(
      {
        submitting: false,
        errors,
        values: { ...values, ...(updatedFormData || {}) },
      },
      () => {
        if (!noScrollToError) {
          scrollToError(usageContext === 'modal');
        }
      },
    );
  };

  setErrors: TContext['setErrors'] = (errors, append) => {
    const { noScrollToError, usageContext } = this.props;

    if (append) {
      this.setState(
        (prevState) => ({
          submitting: false,
          errors: { ...prevState.errors, ...errors },
        }),
        () => {
          if (!noScrollToError) scrollToError(usageContext === 'modal');
        },
      );
    } else {
      this.setState({ submitting: false, errors }, () => {
        if (!noScrollToError) scrollToError(usageContext === 'modal');
      });
    }
  };

  get hasErrors() {
    const { errors } = this.state;
    return Object.keys(errors).length > 0;
  }

  setFetchingAddress: TContext['setFetchingAddress'] = (fetchingAddress) => {
    this.setState({ fetchingAddress });
  };

  submitForm = async (partialValuesToUpdate?: Partial<TValues>) => {
    const { errors } = this.props;

    const { submitting } = this.state;
    if (submitting) return;

    flushSync(() => {
      this.setState((prevState) => {
        const newState: State<TValues> = {
          ...prevState,
          submitting: true,
          // reset the errors on form submission
          errors: errors ? prevState.errors : {},
        };

        if (partialValuesToUpdate) {
          newState.values = { ...prevState.values, ...partialValuesToUpdate };
        }

        return newState;
      });
    });

    let hasInterceptorFailed = false;
    for (let i = 0; i < this.submitInterceptors.length; i++) {
      const interceptor = this.submitInterceptors[i];
      try {
        // TODO: drop this eslint rule?
        // eslint-disable-next-line no-await-in-loop
        await interceptor();
      } catch (error) {
        hasInterceptorFailed = true;
      }
    }

    const performSubmit = async () => {
      const { onSubmit } = this.props;
      const { values: stateValues } = this.state;

      const values = { ...stateValues, ...partialValuesToUpdate };

      if (hasInterceptorFailed) return;

      if (onSubmit) {
        await onSubmit({
          values: values as TValues,
          updateValues: this.updateValues,
          updateValueWith: this.updateValueWith,
          updateSubmitting: this.updateSubmitting,
          clearErrors: this.clearErrors,
          clearValues: this.clearValues,
          handleError: this.handleError,
          setErrors: this.setErrors,
          setFetchingAddress: this.setFetchingAddress,
        });

        // This setTimeout causes the interceptor to be called on the "next tick".
        //
        // This means that at the time the function is called, the form state has
        // already been updated and contains the latest data such as `errors`
        // and `values`
        setTimeout(() => {
          this.submittedInterceptors.forEach((interceptor) => interceptor());
        }, 1);
      }
    };

    const { fetchingAddress } = this.state;
    if (fetchingAddress) {
      this.onFetchingAddressComplete = performSubmit;
    } else {
      performSubmit();
    }
  };

  /**
   * @returns a function to remove the interceptor
   */
  addSubmitInterceptor = (interceptor: SubmitInterceptor) => {
    this.submitInterceptors.push(interceptor);

    return () => {
      const index = this.submitInterceptors.indexOf(interceptor);
      this.submitInterceptors.splice(index, 1);
    };
  };

  /**
   * @returns a function to remove the interceptor
   */
  addSubmittedInterceptor = (interceptor: SubmittedInterceptor) => {
    this.submittedInterceptors.push(interceptor);

    return () => {
      const index = this.submittedInterceptors.indexOf(interceptor);
      this.submittedInterceptors.splice(index, 1);
    };
  };

  render() {
    const {
      values,
      errors,
      submitting,
      submitDisabled,

      edited,
    } = this.state;

    const {
      component,
      id,
      className,
      formRef,
      render,
      children,
      'data-qa-id': dataQaId,
      'data-qa': dataQa,
    } = this.props;

    const dataQaProps = arrToObject(
      Object.entries(dataQa || {}).map(([key, value]) => [
        `data-qa-${key}`,
        value,
      ]),
    );

    const FormComponent = component || 'form';

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue: FormContextValue<TValues> = {
      values: values as TValues,
      getValue: partial(getValue, values),
      updateValue: this.updateValue,
      updateValues: this.updateValues,
      clearValues: this.clearValues,
      updateValueWith: this.updateValueWith,
      setFetchingAddress: this.setFetchingAddress,
      errors,
      setErrors: this.setErrors,
      clearErrors: this.clearErrors,
      submitting,
      updateSubmitting: this.updateSubmitting,
      submitDisabled,
      updateSubmitDisabled: this.updateSubmitDisabled,
      fieldQaIdPrefix: dataQaId,
      edited,
      handleError: this.handleError,
      addSubmitInterceptor: this.addSubmitInterceptor,
      addSubmittedInterceptor: this.addSubmittedInterceptor,
      submitForm: this.submitForm,
    };

    const renderProps: FormRenderProps<TValues> = {
      values: values as TValues,
      getValue: partial(getValue, values),
      updateValue: this.updateValue,
      updateValueWith: this.updateValueWith,
      updateValues: this.updateValues,
      clearValues: this.clearValues,
      hasErrors: this.hasErrors,
      setErrors: this.setErrors,
      submitting,
      submitDisabled,
      updateSubmitDisabled: this.updateSubmitDisabled,
      errors,
      submitForm: this.submitForm,
      edited,
      handleError: this.handleError,
      clearErrors: this.clearErrors,
      fieldQaIdPrefix: dataQaId,
    };

    return (
      <FormContext.Provider value={contextValue}>
        <FormComponent
          ref={formRef}
          data-qa-id={dataQaId}
          onSubmit={(e) => {
            e.preventDefault();
            // This class will be removed on the next render unless submitting == true
            e.currentTarget.classList.add(formSubmittingClassName);
            this.submitForm();
          }}
          className={[className, submitting && formSubmittingClassName]
            .filter(Boolean)
            .join(' ')}
          id={id}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dataQaProps}
          noValidate
        >
          {render ? render(renderProps) : children}
        </FormComponent>
      </FormContext.Provider>
    );
  }
}
