import queryString from 'query-string';

import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import {
  Action,
  generateRedirectUrl,
  saveTemporaryStorage,
} from 'utils/temporaryStorage';

type ThirdPartyLoginProvider = 'facebook' | 'google' | 'apple';

type Args = {
  provider: ThirdPartyLoginProvider;
  redirectTo: string | null | undefined;
  inviteId?: string | null | undefined;
  cmsSubscribe?: CmsApiSubscribe;
  savedListing?: { id: string; type: string };
  savedSearch?: ApiSavedSearchUnsaved;
  subscribedSubsite?: string | null | undefined;
};

type ThirdPartyLoginQueryParams = {
  provider: ThirdPartyLoginProvider;
  invite?: string;
  to?: string;
};

export async function getThirdPartyLoginUrl({
  provider,
  redirectTo,
  inviteId,
  cmsSubscribe,
  savedListing,
  savedSearch,
  subscribedSubsite,
}: Args) {
  const queryParams: ThirdPartyLoginQueryParams = { provider };

  const actions: Action[] = [];

  if (inviteId) {
    queryParams.invite = inviteId;
  }

  if (savedListing) {
    actions.push({ type: 'SAVE_LISTING', data: savedListing });
  }

  if (savedSearch) {
    actions.push({ type: 'SAVE_SEARCH', data: savedSearch });
  }

  if (cmsSubscribe) {
    actions.push({ type: 'CMS_SUBSCRIBE', data: cmsSubscribe });
  }

  if (subscribedSubsite) {
    actions.push({
      type: 'SUBSCRIBE_SUBSITE',
      data: { subscribedSubsiteId: subscribedSubsite },
    });
  }

  if (actions.length > 0 && redirectTo) {
    const id = await saveTemporaryStorage(actions);
    const redirectToWithTmpId = generateRedirectUrl(redirectTo, id);
    queryParams.to = redirectToWithTmpId;
  } else if (redirectTo) {
    queryParams.to = redirectTo;
  }

  return `/third-party-login?${queryString.stringify(queryParams)}`;
}
