import {
  FocusEvent,
  MutableRefObject,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import deepEqual from 'react-fast-compare';

import { FormContextValue } from 'components/Form/FormContext';
import { isDefined } from 'utils/functional';

import { FieldCustomErrorMatcher } from './FieldCustomErrorMatcher';
import { FieldGroup } from './FieldGroup';
import { getFieldQaId } from './getFieldQaId';

export type FieldInternalProps<TValues extends Record<string, unknown>> = {
  className?: string;
  getValue: FormContextValue<TValues>['getValue'];
  fieldQaIdPrefix: string;
  values: TValues;
  labelRef: MutableRefObject<HTMLLabelElement | null>;
  errorRef?: RefObject<HTMLDivElement | null>;
  updateValue: FormContextValue<TValues>['updateValue'];
  updateValueWith: FormContextValue<TValues>['updateValueWith'];
  errors: APIErrors;
  name: string;
  nameOverride?: string;
  label?: ReactNode;
  inlineLabel?: boolean;
  hidden?: boolean;
  description?: ReactNode;
  renderInput: (params: {
    name: string;
    fieldQaIdPrefix: string;
    labelRef: MutableRefObject<HTMLLabelElement | null>;
    values: TValues;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    id: string;
    qaId: string;
    errors: APIErrors;
    hasError: boolean;
    hasSuccess: boolean;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue: (value: any) => void;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValueWith: (arg0: (...args: Array<any>) => any) => void;
    getValue: FormContextValue<TValues>['getValue'];
    updateValueByName: FormContextValue<TValues>['updateValue'];
    onBlur: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
    onFocus: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  }) => ReactNode;
  mapError: (error: string) => ReactNode;
  matchGeneralError?: string;
  // @NOTE - `inputProps` is only used in the memoization, but removing this causes an error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unused-prop-types
  inputProps?: Record<string, any>;
  // eslint-disable-next-line react/no-unused-prop-types
  customCompare?: (
    previousProps: FieldInternalProps<TValues>,
    newProps: FieldInternalProps<TValues>,
  ) => boolean;
  customErrorMatch?: FieldCustomErrorMatcher;
  noMargin?: boolean;
  noWrap?: boolean;
  success?: boolean;
  customError?: string | null | undefined;
};

function UnmemoedFieldInternal<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TValues extends Record<string, unknown> = Record<string, any>,
>({
  className,
  getValue,
  fieldQaIdPrefix,
  renderInput,
  values,
  labelRef,
  errorRef,
  errors,
  updateValue,
  updateValueWith,
  inlineLabel,
  hidden,
  name,
  nameOverride,
  label,
  description,
  matchGeneralError,
  mapError,
  customErrorMatch,
  noMargin,
  noWrap,
  success,
  customError,
}: FieldInternalProps<TValues>) {
  const [hasFocus, setHasFocus] = useState(false);
  const onFocus = () => setHasFocus(true);
  const onBlur = () => setHasFocus(false);

  const value = getValue(name);

  const id = useMemo(() => {
    if (fieldQaIdPrefix)
      return `${fieldQaIdPrefix}-${getFieldQaId(nameOverride || name)}`;
    return getFieldQaId(nameOverride || name);
  }, [fieldQaIdPrefix, name, nameOverride]);

  const error: ReactNode | undefined = useMemo(() => {
    const fieldError =
      customError ||
      (customErrorMatch ? customErrorMatch(errors) : errors[name]);

    if (fieldError) return mapError(fieldError);

    const generalErrorMessage = errors[''] || errors.general;

    const hasGeneralError =
      isDefined(matchGeneralError) && generalErrorMessage === matchGeneralError;

    if (hasGeneralError) return generalErrorMessage;

    return undefined;
  }, [
    customError,
    customErrorMatch,
    errors,
    mapError,
    matchGeneralError,
    name,
  ]);

  const updateValueForField = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (newValue: any) => {
      updateValue(name, newValue);
    },
    [updateValue, name],
  );

  const input = renderInput({
    fieldQaIdPrefix,
    labelRef,
    name,
    values,
    value,
    id,
    qaId: id,
    errors,
    hasError: Boolean(error),
    hasSuccess: Boolean(success),
    updateValue: updateValueForField,
    updateValueByName: updateValue,
    updateValueWith: (newValue) => updateValueWith(name, newValue),
    getValue,
    onFocus,
    onBlur,
  });

  return (
    <FieldGroup
      label={label}
      labelRef={labelRef}
      errorRef={errorRef}
      description={description}
      inputId={id}
      className={className}
      inlineLabel={inlineLabel}
      hidden={hidden}
      noMargin={noMargin}
      noWrap={noWrap}
      error={error}
      hasFocus={hasFocus}
      hasValue={isDefined(value)}
    >
      {input}
    </FieldGroup>
  );
}

export const FieldInternal = memo(UnmemoedFieldInternal, (pp, np) => {
  if (
    pp.name !== np.name ||
    np.label !== pp.label ||
    np.customError !== pp.customError ||
    np.success !== pp.success
  ) {
    return false;
  }

  const customComparison = np.customCompare ? np.customCompare(pp, np) : true;
  if (!customComparison) return false;

  const value = np.getValue(np.name);
  const prevValue = pp.getValue(pp.name);
  if (!deepEqual(value, prevValue)) return false;

  if (!deepEqual(pp.errors, np.errors)) return false;
  if (!deepEqual(pp.inputProps, np.inputProps)) return false;

  return true;
});
